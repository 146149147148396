import React from "react";
import '../../App.css';
//const MetaTags = require('react-meta-tags');

function Home(){
    return (
      <div>
        {/* <MetaTags>
           <title>Matt Feeney Photography</title>
           <meta name="description" content="Matt Feeney Photography - Professional Photography Service based Lincoln" />
         </MetaTags> */}
         <div className="row justify-content-center">
              <img width="100%" className="col-11" src="images/banners/home.jpg" loading="lazy" />
          </div>
          <div className="row justify-content-center">
            <img src="images/recent-work/red-light.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/roses.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/crack-of-light.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/blue-railing.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/swing.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/bk.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/grapes.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/orange-chair.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/milking-it.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/tube-man.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/glass-and-shadow.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/croc.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/moment.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/ketchup.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/ladies.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/wild-garlic.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/post-breakfast.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/abstract.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/church.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/open.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/heart-shaped-hole.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/tim.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/red-flower.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/easy-sunday.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/floating.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/cherry-curtains.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/piering.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
              <img src="images/recent-work/remains.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />

            <img src="images/recent-work/man-in-white.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/late-night-laundrette.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/food-van.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/coke-can.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/lisa-birds.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/icecream-people.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/looking-at-shore.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/pink.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/guard.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/protest.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/dragon-ride.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/yellow-slice.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/green-stairs.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            <img src="images/recent-work/empty-chair.jpg" className="col-11 col-lg-3 mt-5"   width="100%" loading="lazy" />
            
         </div>
      </div>
    );
}
export default Home;
