import React from 'react';
import {
  Route,
  BrowserRouter,
  Switch,
  NavLink
} from "react-router-dom";

import './App.css';
import './vendor/js/bootstrap.min.css'
import Header from "./components/header/";
import PageMissing from './components/pageMissing/';
import Home from "./pages/home/";
import Blog from "./pages/blog/";
import SignsOfLife from "./pages/projects/signs-of-life/signsOfLife";
import LostAndFound from "./pages/projects/lost-and-found/lostAndFound";
import About from "./pages/about/";
import BlogPost from "./pages/blogpost";
import Contact from "./pages/contact/";
import { Wolds } from './pages/projects/wolds';
import { Scampton } from './pages/projects/scampton';
import { BreakfastFood } from './pages/projects/breakfastFoods';
import { RealWrasslin } from './pages/projects/realWrasslin';
import { TakeMeToChurch } from './pages/projects/takeMeToChurch';

function App () {
    return (
      <div className="App">
        <BrowserRouter>
          <div>
            <div className="App-intro">
              <Header />
              <div className='row'>
              <div className='col-2 nav-link d-none d-md-block d-lg-block'>
                <div className='nav-menu'>
                      <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../projects/breakfast-food">
                        BREAKFAST FOOD
                      </NavLink>
                      {/* <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../projects/take-me-to-church">
                        TAKE ME TO CHURCH
                      </NavLink> */}
                      <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../projects/real-wrasslin">
                        REAL WRASSLIN'
                      </NavLink>
                      <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../Blog">
                        BLOG
                      </NavLink>
                      <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../Contact">
                        CONTACT
                      </NavLink>
                      <NavLink className="col-12 nav-link d-none d-md-block d-lg-block mt-2" to="../About">
                        ABOUT
                      </NavLink>
                      <div className="col-12">
                      <hr />
                      </div>
                     
                      <a href="https://unsplash.com/@matt__feeney" rel="noopener noreferrer" target="_blank"><svg className="icon" version="1.1" viewBox="0 0 32 32" height="20" width="20" aria-label="unsplash link" aria-hidden="false"><title id="unsplash-home">Unsplash Link</title><path d="M10 9V0h12v9H10zm12 5h10v18H0V14h10v9h12v-9z"></path></svg></a>
                      <a href="https://www.instagram.com/matt__feeney/" rel="noopener noreferrer" target="_blank"><svg className="" aria-label="instagram link" aria-hidden="false" xmlns="http://www.w3.org/2000/svg" width="26" height="26" viewBox="0 0 24 24"><path d="M12 2.163c3.204 0 3.584.012 4.85.07 3.252.148 4.771 1.691 4.919 4.919.058 1.265.069 1.645.069 4.849 0 3.205-.012 3.584-.069 4.849-.149 3.225-1.664 4.771-4.919 4.919-1.266.058-1.644.07-4.85.07-3.204 0-3.584-.012-4.849-.07-3.26-.149-4.771-1.699-4.919-4.92-.058-1.265-.07-1.644-.07-4.849 0-3.204.013-3.583.07-4.849.149-3.227 1.664-4.771 4.919-4.919 1.266-.057 1.645-.069 4.849-.069zm0-2.163c-3.259 0-3.667.014-4.947.072-4.358.2-6.78 2.618-6.98 6.98-.059 1.281-.073 1.689-.073 4.948 0 3.259.014 3.668.072 4.948.2 4.358 2.618 6.78 6.98 6.98 1.281.058 1.689.072 4.948.072 3.259 0 3.668-.014 4.948-.072 4.354-.2 6.782-2.618 6.979-6.98.059-1.28.073-1.689.073-4.948 0-3.259-.014-3.667-.072-4.947-.196-4.354-2.617-6.78-6.979-6.98-1.281-.059-1.69-.073-4.949-.073zm0 5.838c-3.403 0-6.162 2.759-6.162 6.162s2.759 6.163 6.162 6.163 6.162-2.759 6.162-6.163c0-3.403-2.759-6.162-6.162-6.162zm0 10.162c-2.209 0-4-1.79-4-4 0-2.209 1.791-4 4-4s4 1.791 4 4c0 2.21-1.791 4-4 4zm6.406-11.845c-.796 0-1.441.645-1.441 1.44s.645 1.44 1.441 1.44c.795 0 1.439-.645 1.439-1.44s-.644-1.44-1.439-1.44z"/></svg></a>
                      </div>
              </div>
              <div className='col-md-10 col-sm-12 main-container'>
              <Switch>
                <Route exact path="/" component={Home}/>
                <Route exact path="/blog" component={Blog}/>
                <Route exact path="/about" component={About}/>
                <Route exact path="/contact" component={Contact}/>
                <Route path="/blog/:id" component={BlogPost}/>
                <Route exact path="/projects/lost-and-found" component={LostAndFound}/>
                <Route exact path="/projects/breakfast-food" component={BreakfastFood}/>
                <Route exact path="/projects/take-me-to-church" component={TakeMeToChurch}/>
                <Route exact path="/projects/real-wrasslin" component={RealWrasslin}/>
                <Route exact path="/projects/signs-of-life" component={SignsOfLife}/>
                <Route exact path="/projects/village-people" component={Wolds}/>
                <Route exact path="/projects/scampton" component={Scampton}/>
                <Route component={PageMissing}/>
              </Switch>
              </div>
              </div>
            </div>
           
          </div>
        </BrowserRouter>
      </div>
    );
  }

export default App;
